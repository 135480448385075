<template>
  <!-- ページ全体共通レイアウト -->
  <b-container class="py-4">
    <h1>{{ title }}管理</h1>
    <slot name="pxr"></slot>
    <section id="search" class="mb-5">
      <!-- 検索 -->
      <b-card no-body bg-variant="light">
        <b-card-body>
          <b-card-title title-tag="h2">
            {{ title }}検索 <small>（*の項目は部分一致）</small>
          </b-card-title>
          <slot name="search"></slot>
        </b-card-body>
      </b-card>
    </section>
    <section id="list" class="mb-5">
      <!-- 検索結果一覧 -->
      <b-card no-body>
        <b-card-body>
          <b-card-title title-tag="h2"> {{ title }}一覧 </b-card-title>
          <slot name="list">
            <span class="text-csub"
              >＊検索された{{ title }}の一覧が表示されます</span
            >
          </slot>
        </b-card-body>
      </b-card>
    </section>
    <section id="detail" class="mb-5">
      <!-- 詳細 -->
      <b-card no-body>
        <b-card-body>
          <b-card-title title-tag="h2">
            {{ title }}詳細 {{ subTitle }}</b-card-title
          >
          <slot name="detail">
            <span class="text-csub"
              >＊一覧で選択された{{ title }}の詳細が表示されます</span
            >
          </slot>
        </b-card-body>
      </b-card>
    </section>
  </b-container>
</template>

<script>
export default {
  props: ['title', 'subTitle'],
}
</script>
